@import "~antd/dist/antd.css";

.App {
  text-align: center;
}
html {
  --antd-wave-shadow-color: #05fca9;
}
body {
  background-color: #fff;
}
textarea {
  resize: none;
}
/* button */
.button {
  border: 1px solid #fff;
  border-radius: 5px !important;
  margin: 2px;
}
.button::after,
.button:hover,
.button:focus {
  color: #fff !important;
  background-color: #05fca9;
  border-color: rgba(0, 0, 0, 0);
}
/* logo */
.logo {
  height: 10em;
}
.logo-no-text {
}
.logo-small {
  max-height: 55px;
}

/* logo */

/* nav */
.navbar {
  z-index: 1000;
  background-color: #05fca9;
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.nav-item-container {
  width: 100%;
  max-width: 1860px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
}
.is-menu-container,
.is-list-container {
  background-color: #05fca9;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
  margin-bottom: 1em;
}
.is-menu-item,
.is-list-item {
  background-color: #fff;
  margin-top: 1px !important;
  margin-bottom: 1px !important;
  text-align: center;
}
.is-menu-item:hover,
.is-list-item {
  opacity: 95%;
}
.menu-first,
.list-first {
  margin-top: 0 !important;
}
.menu-last,
.list-last {
  margin-bottom: 0 !important;
}
.is-expandable-expanded {
  height: 250px !important;
  transition: height 0.5s ease-out;
}
.is-expandable-collapsed {
  height: 40px !important;
  transition: height 0.5s ease-out;
}
.is-expandable-collapsed:hover {
  cursor: pointer !important;
}
.menu-dissolve {
  opacity: 0;
  transition: opacity 0.5s ease-out;
}
.menu-appear {
  opacity: 1;
}

/* nav */

/* sidebar */
.sidebar {
  position: fixed;
  background-color: #fff;
  z-index: 999;
  border-right: 1px solid #ccc;
  height: 90%;
}
.sidebar-menu {
}
.sidebar-active {
}
.sidebar-inactive {
  width: 0px !important;
}
/* sidebar */

/* main layout */
/* //////////// */
.main-layout {
  display: flex;
  align-items: center;
}
.main-content {
  max-width: 1024px;
  width: 100%;
  border: 5px;
  padding-bottom: 25px;
}
/* home page */
.home-page {
  max-width: 750px;
  width: 100%;
  padding: 1em;
  background-color: #fff;
}
/* home page */

/* profile */
.profile {
}
.headshot {
  height: 100%;
}
.banner {
  max-width: 100%;
}

/* profile */

/* //////////// */
/* main layout */

/* posts */

.post-container {
  width: 100%;
  border-radius: 5px;
}
.post {
  border-top: 1px solid #ccc;
}
.last-post {
}
.first-post {
}

/* footer */
.footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #05fca9;
  z-index: 1000;
}
/* footer */

/* @media wider than 768px */
@media only screen and (min-width: 768px) {
  .nav-item-container {
    justify-content: space-around;
  }
  .main-content {
    box-shadow: 0 4px 8px 0 #ccc;
    transition: 0.3s;
    margin-top: 1em;
  }
  .banner {
    padding: 0.5em;
    max-width: 99%;
  }
  .stack-cols-mobile {
    flex-direction: row !important;
  }
  .stack-cols-mobile-rev {
    flex-direction: row !important;
  }
  .first-post {
    border-top: 1px solid #ccc;
  }
}

/* helpers */
.is-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.is-overflow-hidden {
  overflow: hidden;
}
.stack-cols {
  flex-direction: column;
}
.stack-cols-mobile {
  flex-direction: column;
}
.stack-cols-mobile-rev {
  flex-direction: column-reverse;
}
.text-align-left {
  text-align: start;
}
.has-spacer-padding {
  padding: 0.75em;
}
.has-spacer-padding-bottom {
  padding-bottom: 0.75em;
}
.has-spacer-padding-top {
  padding-top: 0.75em;
}
.has-spacer-margin {
  margin: 1.5em;
}
.bg-brand {
  background-color: #05fca9;
  color: #fff;
}
.has-border-basic {
  border: 1px solid #ccc;
}
.has-border-basic-bottom {
  border-bottom: 1px solid #ccc;
}
.is-fullWidth {
  width: 100%;
}
.link-hover:hover {
  cursor: pointer;
  color: #1890ff;
}
.border-top-none {
  border-top: none !important;
}
.tool-tip-hover:hover {
  cursor: pointer;
}
/* overrides */
.ant-layout {
  background-color: #fff;
}
.ant-layout-sider-children {
  width: 100%;
}
li,
.ant-menu-item,
.ant-menu-submenu-title:hover,
.ant-menu-item-selected {
  opacity: 1 !important;
  background-color: #fff !important;
  color: unset !important;
  cursor: unset;
}
p {
  margin: 0 !important;
}
.ant-input-affix-wrapper:active,
.ant-input-affix-wrapper:hover,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border: 1px solid rgb(175, 175, 175) !important;
}
.ant-input,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  box-shadow: none !important;
  border-color: rgb(150, 150, 150, 0.5) !important;
}
.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border: 1px solid #f0f0f0;
}
